<template>
	<div>
		<PageTitle title="康宁云医院" fixed :backTo="'/accountJudgment/' + selectIndex +'/0/0'"></PageTitle>
		<div style="position: relative;width: 85%;margin: 15px auto auto auto;">
			<van-button type="primary" block style="width: 40%;" @click="setDefault">设为默认账户</van-button>
			<List :width="100">
				<ListItem :leftWidth="0" :rightWidth="100">
					<template #top>
						<img v-show="verification==1" src="../assets/verification-green.png">
						<p style="text-align: center">个人账户</p>
					</template>
					<template v-slot:right>
						<div class="cardContent">
						<div class="margintb">
							<div style="display: flex;align-items: center;">
								<van-icon name="https://b.yzcdn.cn/vant/icon-demo-1126.png" size="45" color="#3f9775" />
								<div>
									<p class="width50"><van-tag type="success" size="medium">姓名</van-tag> {{idDetail.brxm}}</p>
									<p class="width50"><van-tag type="success" size="medium">性别</van-tag> {{idDetail.brxb}}</p>
									<p class="width50"><van-tag type="success" size="medium">年龄</van-tag> {{moment().diff(idDetail.birthday, 'years')}}</p>
									<p class="width50"><van-tag type="success" size="medium">民族</van-tag> {{idDetail.mz}}</p>
								</div>	
							</div>
							<hr>
							<p style="margin: 0 0 5px 0;"><van-tag type="success"   size="medium">身份证号</van-tag> {{idDetail.sfzh|filterIdCardNum}}</p>
							<p><van-tag type="success"   size="medium">就诊卡号</van-tag> {{idDetail.ckhm | filterCardNum}}</p>
						</div>
						<div class="margintb">
							<p>
								<van-tag type="success" size="medium">性质</van-tag> 
							{{idDetail.xzmc}}
							</p>
						</div>
						<div class="margintb">
							<p>
								<van-tag type="success" size="medium"> 账户余额</van-tag> {{idDetail.zhye}}元 
								<van-tag type="success" size="medium"> 可退金额</van-tag> {{idDetail.ktye}}元
							</p>
						</div>
						<hr>
						<div class="margintb">
							<p><van-tag type="success" size="medium">绑定手机号</van-tag><br>{{idDetail.sjhm}}</p>
						</div>
						<div class="margintb">
							<p><van-tag type="success" size="medium">住址</van-tag> <br>{{idDetail.address}}</p>
						</div>
					</div>
					
					</template>
				</ListItem>
			</List>
			<footer>
				<van-button type="primary" block size="small" @click="unbind">解绑</van-button>
				<van-button type="primary" block size="small" @click="toPay">充值</van-button>
				<van-button type="primary" block size="small" :to="'/refund/' + selectIndex+'/'+idDetail.ckhm">退款
				</van-button>
				<van-button type="primary" block size="small"
					:to="'/check/' + idDetail.ckhm+'/'+idDetail.brxm+'/'+idDetail.xzmc+'/0'">账单明细</van-button>
			</footer>
		</div>
	</div>
</template>

<script>
	import PageTitle from '@/components/PageTitle.vue';
	import List from '@/components/home-page/List.vue';
	import ListItem from '@/components/home-page/ListItem.vue';
	import {
		SetDefaultAccount,
		AccountDebind,
		QueryAccount
	} from '@/api/user.js';
  import moment from 'moment';

	export default {
		components: {
			PageTitle,
			List,
			ListItem,
		},
		data() {
			return {
        moment,
				idDetail: {
					id: 0,
					ckhm: "",
					brxm: "",
					brxb: "",
					sfzh: "",
					brxz: "",
					xzmc: "",
					zhye: 0,
					ktye: 0,
					mrzh: "",
					sjhm: "",
					address: "",
					nl: "",
					birthday: "",
					mz: "",
					zhyxpb: "",
					sfzpb: "",
					phonePB: "",
					namepb: "",
					srbrxm: "",
					srsfzh: "",
					srphone: ""
				},
				verification: 2,
				selectIndex: this.$route.params.selectIndex || localStorage.getItem('selectIndex'),
			}
		},
		mounted() {
			this.$toast('账户加载中')
			QueryAccount(4, this.$route.params.cardId, this.$store.getters.userInfo.UserId).then(
				(data) => {
					if (data.length) {
						this.idDetail = data[0];
						this.verification = data[0].mrzh;
					} else {
						this.$dialog.alert({
							message: 'error', //提示内容
							confirmButtonText: "当前账户的默认账户存在异常，请检查",
						}).then(() => {
							window.location.replace(window.location.origin + window.location.search +
								"#/accountJudgment/0/0/0");
						});
					}
				},
				() => {}
			);
		},
		methods: {
			setDefault() {
				// if (this.idDetail.brxz == 27) {
				// 	this.$dialog.alert({
				// 		title: '温馨提醒',
				// 		message: '参保地为温州地区以外的人员，暂时不能使用云医院复诊开药，敬请期待',
				// 	}).then(() => {});
				// } else {
				SetDefaultAccount(this.idDetail.ckhm, this.$store.getters.userInfo.UserId).then(
					() => {
						this.$store.SaveSocket = '';
						this.$dialog.alert({
							title: '温馨提醒',
							message: '设置当前账号为默认账号成功',
						}).then(() => {
							// on confirm
							this.verification = 1;
							this.idDetail.mrzh = 1;
						});
					},
					() => {}
				);
				// }
			},
			unbind() {
				if(this.idDetail.zhye > 0 ){
					return	this.$toast('您的账户内存有余额\n无法解绑')
					
				}
				if(this.idDetail.xzmc != '自费' ){
					return	this.$toast(`您的账户类型为${this.idDetail.xzmc}，暂不支持解绑！`)
				}
				this.$dialog.confirm({
					title: '温馨提醒',
					message: '解绑后，该账户将会移除，确认继续解绑吗',
					// message: '解绑后，该账户将不再显示\n如果想要再次使用\n需要重新验证绑定',
					confirmButtonText: '取消',
					cancelButtonText: '继续'
				}).then(() => {
					// on right
				}).catch(() => {
					// on left
					AccountDebind(this.idDetail.ckhm, this.$store.getters.userInfo.UserId).then(
						() => {
							this.$dialog.alert({
								title: '温馨提醒',
								message: '此账号已解绑成功',
							}).then(() => {
								// on confirm
								this.$router.push("/accountJudgment/0/0/0");
							});
						},
						() => {}
					);
				});
			},
			toPay() {
				localStorage.setItem('payDepartmentId', '0');
				localStorage.setItem('payDoctorId', '0');
				localStorage.setItem('payCardId', this.idDetail.ckhm);
				localStorage.setItem('payPatinetId', this.$route.params.cardId);
				localStorage.setItem('payPreRoute', 'accountInformation');
				localStorage.setItem('selectIndex', this.selectIndex);
				this.$router.push('/recharge/1');
			}
		}
	}
</script>

<style scoped="" lang="less">
	@import "../style/var.less";

	footer .van-button {
		width: 23%;
		display: inline-block;
		margin: auto 1%;
	}

	.infoContainer {
		margin-top: 15px;
		padding: 10px;

		p {
			font-size: @font-size-md;
			margin: 0;
		}

		img {
			position: absolute;
			top: -10px;
			right: -10px;
			width: 60px;
		}

		.margintb {
			margin: 10px auto 10px 5px;
		}

		.width50 {
			width: 50%;
			display: inline-block;
		}

		.top p {
			text-align: center;
			margin: 0 0 10px;
		}

		.left .van-button,
		.right .van-button {
			margin: 0 0 10px;
		}

		.van-cell {
			border: 1.5px solid @green;
			padding: 4px 16px;
			border-radius: 8px;
			box-shadow: 0px 0px 5px 0px @gray-6 inset;

		}
	}
	div.cardContent{
		p{

			font-size: 16px;
		}
		letter-spacing: 1px;
		img{
			position: static;
		}
	}
</style>
